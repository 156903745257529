const BACKEND_URL = "http://51.158.152.2:5000";

/**
 * Fetches the available test servers from the backend.
 * @returns {Promise<Object>} List of test servers.
 */
export const fetchTestServers = async () => {
  try {
    const response = await fetch(`${BACKEND_URL}/test-servers`);
    if (!response.ok) {
      throw new Error("Greška pri dobavljanju servera.");
    }
    return response.json();
  } catch (error) {
    console.error("Greška u fetchTestServers:", error);
    throw error;
  }
};

/**
 * Measures the download speed for a given URL.
 * @param {string} url - The URL to download the test file from.
 * @param {function} onProgress - Callback function to handle progress updates.
 * @returns {Promise<Object>} Speed and duration of the download.
 */
export const measureDownloadSpeed = async (url, onProgress) => {
  try {
    const startTime = performance.now();
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Greška pri preuzimanju s URL-a: ${url}`);
    }

    const reader = response.body.getReader();
    let downloadedBytes = 0;
    const contentLength = response.headers.get("Content-Length") || 100 * 1024 * 1024; // Default 100MB

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      downloadedBytes += value.length;
      const progress = (downloadedBytes / contentLength) * 100;
      if (onProgress) {
        onProgress(progress.toFixed(2)); // Napredak u procentima
      }
    }

    const endTime = performance.now();
    const duration = (endTime - startTime) / 1000; // in seconds
    const speedMbps = ((downloadedBytes * 8) / (duration * 1_000_000)).toFixed(2);

    return { speed: speedMbps, duration: duration.toFixed(2) };
  } catch (error) {
    console.error("Greška u measureDownloadSpeed:", error);
    throw error;
  }
};

/**
 * Fetches the client's IP address.
 * @returns {Promise<string>} Client's public IP address.
 */
export const fetchClientIP = async () => {
  try {
    const response = await fetch("https://api64.ipify.org?format=json");
    if (!response.ok) {
      throw new Error("Greška pri dobavljanju IP adrese klijenta.");
    }
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Greška u fetchClientIP:", error);
    throw error;
  }
};