import React, { useState } from "react";
import Header from "./components/Header";
import Status from "./components/Status";
import ProgressBar from "./components/ProgressBar";
import Results from "./components/Results";
import ThemeToggle from "./components/ThemeToggle";
import { fetchTestServers, measureDownloadSpeed, fetchClientIP } from "./api";
import "./App.css";

const App = () => {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("Čekanje...");
  const [results, setResults] = useState([]);
  const [isTesting, setIsTesting] = useState(false);
  const [clientIP, setClientIP] = useState("");

  const startTest = async () => {
    setIsTesting(true);
    setProgress(0);
    setStatus("Dobavljanje IP adrese...");
    setResults([]);

    try {
      const ip = await fetchClientIP();
      setClientIP(ip);

      setStatus("Dobavljanje servera...");
      const servers = await fetchTestServers();

      setStatus("Pokretanje testa...");
      for (const [serverName, serverUrl] of Object.entries(servers)) {
        setStatus(`Testiranje: ${serverName}`);
        const result = await measureDownloadSpeed(serverUrl, (progress) => {
          setProgress(progress);
        });

        setResults((prev) => [
          ...prev,
          { serverName, ...result },
        ]);
      }

      setStatus("Test završen.");
    } catch (error) {
      setStatus(`Greška: ${error.message}`);
    } finally {
      setIsTesting(false);
      setProgress(100);
    }
  };

  return (
    <div className="app-container">
      <ThemeToggle />
      <Header />
      <main>
        {clientIP && <p>IP Klijenta: {clientIP}</p>}
        <button
          className="btn-primary"
          onClick={startTest}
          disabled={isTesting}
        >
          {isTesting ? "Testiranje u toku..." : "Pokreni test"}
        </button>
        <Status status={status} />
        <ProgressBar progress={progress} />
        <Results results={results} />
      </main>
    </div>
  );
};

export default App;