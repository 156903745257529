import React from "react";

const Results = ({ results }) => (
  <div className="results-container">
    {results.map((result, index) => (
      <div key={index} className="result-card">
        <h3>{result.serverName}</h3>
        <p>Brzina: {result.speed} Mbps</p>
        <p>Trajanje: {result.duration} sekundi</p>
      </div>
    ))}
  </div>
);

export default Results;