import React from "react";
import { FaNetworkWired } from "react-icons/fa";

const Header = () => (
  <header className="header transparent-header">
    <h1>
      <FaNetworkWired /> ANJA-LAZANJA
    </h1>
  </header>
);

export default Header;