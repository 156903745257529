import React, { useEffect, useState } from "react";
import { FaSun, FaMoon } from "react-icons/fa";

const ThemeToggle = () => {
  const [theme, setTheme] = useState(() => {
    // Proveri da li postoji tema u localStorage
    const savedTheme = localStorage.getItem("theme");
    return savedTheme || "dark"; // Default je "dark"
  });

  useEffect(() => {
    // Postavi temu na root elementu (html)
    document.documentElement.setAttribute("data-theme", theme);
    // Sačuvaj korisničku preferenciju u localStorage
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <button className="theme-toggle" onClick={toggleTheme} aria-label="Toggle Theme">
      {theme === "light" ? <FaMoon /> : <FaSun />}
    </button>
  );
};

export default ThemeToggle;